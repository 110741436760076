// data
import pluginData from '../../data/pluginData.json';
import { AnimationWrap } from '../../helper/animationWrap';
import TitleHelper from '../../helper/TitleHelper';

export const Title = () => {
	return (
		<AnimationWrap>
			<TitleHelper
				heading={pluginData.title}
				special={pluginData.special}
				titleType="h2"
			/>
			<p>{pluginData.content}</p>
		</AnimationWrap>
	);
};
