import { AnimationWrap } from '../../helper/animationWrap';
import cycode from '../../assets/img/powered-by-cycode-white.svg';

import { FooterItem } from './types';

type FooterProps = {
	footerItem: FooterItem;
};

export const Inner = ({ footerItem }: FooterProps) => {
	return (
		<AnimationWrap extraClass="footer-inner" tag="div">
			<div className="widget">
				<a target="_blank" rel="noreferrer" href="https://cycode.com/">
					<img
						width="200"
						height="20"
						src={cycode}
						alt="powered by cycode"
					/>
				</a>

				<p>{footerItem.cycodeDescription}</p>
			</div>
			<div className="widget links">
				<ul>
					{footerItem.links1.map((item, index) => (
						<li key={index}>
							{!item.isMailto ? (
								<a
									target="_blank"
									rel="noreferrer"
									href={item.link}
								>
									{item.name}
								</a>
							) : (
								<a href={item.link}>{item.name}</a>
							)}
						</li>
					))}
				</ul>

				<ul>
					{footerItem.links2.map((item, index) => (
						<li key={index}>
							<a
								target="_blank"
								rel="noreferrer"
								href={item.link}
							>
								{item.name}
							</a>
						</li>
					))}
				</ul>
			</div>
		</AnimationWrap>
	);
};
