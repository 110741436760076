import React from 'react';

interface FAQItem {
    question: string;
    answer: string[];
    open: boolean;
}

interface FAQProps {
    faq: FAQItem;
    index: number;
    toggleFAQ: (index: number) => void;
}

export const FAQ: React.FC<FAQProps> = ({ faq, index, toggleFAQ }) => {
    return (
        <div
            className={'faq ' + (faq.open ? 'open' : '')}
            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className="faq-question">
                {faq.question}
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.0001 8C16.4735 8 16.8572 8.38376 16.8572 8.85714L16.8572 15.1428L23.1429 15.1428C23.6162 15.1428 24 15.5265 24 15.9999C24 16.4733 23.6162 16.8571 23.1429 16.8571L16.8572 16.8571L16.8572 23.1429C16.8572 23.6162 16.4734 24 16 24C15.5266 24 15.1429 23.6162 15.1429 23.1429L15.1429 16.8571L8.85714 16.8571C8.38376 16.8571 8 16.4734 8 16C8 15.5266 8.38375 15.1428 8.85714 15.1428L15.1429 15.1428L15.1429 8.85714C15.1429 8.38375 15.5267 8 16.0001 8Z"
                        fill="#AEACFF"
                    />
                </svg>
            </div>
            <div className="faq-answer">
                {faq.answer.map((line, i) => {
                    // Check if it's the last line and contains "here"
                    if (i === faq.answer.length - 1 && line.includes("here")) {
                        return (
                            <p key={i}>
                                {line.split("here").map((text, index) => {
                                    if (index === 0) {
                                        return text;
                                    } else {
                                        return (
                                            <React.Fragment key={index}>
                                                <a className="link" target="_blank" rel="noreferrer" href="https://docs.cimon.build/agent/overview/supported-features">here</a>
                                                {text}
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </p>
                        );
                    } else {
                        return <p key={i}>{line}</p>;
                    }
                })}
            </div>
        </div>
    );
};
