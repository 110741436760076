import { Action, Inner, Rights } from './';
import { AnimationWrap } from '../../helper/animationWrap';

import footerItemData from '../../data/footerData.json';

export const Footer = () => {
	return (
		<div className="footer">
			<AnimationWrap
				extraClass="footer-animation-start"
				tag="div"
				animationClass="footer-animation-end"
			>
				<span></span>
			</AnimationWrap>

			<Action footerItem={footerItemData} />
			<Inner footerItem={footerItemData} />
			<Rights footerItem={footerItemData} />
		</div>
	);
};
