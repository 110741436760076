import { useInView } from 'react-intersection-observer';
import { FooterItem } from './types';

type FooterProps = {
	footerItem: FooterItem;
};

export const Action = ({ footerItem }: FooterProps) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	return (
		<div className="action">
			<h2
				ref={ref}
				className={`hide-animation ${inView ? 'show-animation-1' : ''}`}
			>
				{footerItem.title}
			</h2>
			<a
				className={`get-started-btn hide-animation ${
					inView ? 'show-animation-2' : ''
				}`}
				target="_blank"
				rel="noreferrer"
				href={footerItem.buttonLink}
			>
				<svg
					width="26"
					height="27"
					viewBox="0 0 26 27"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13 0.5C5.8175 0.5 0 6.46861 0 13.8299C0 19.7205 3.7245 24.7158 8.88875 26.477C9.53875 26.6025 9.77708 26.1899 9.77708 25.8361C9.77708 25.5195 9.76625 24.6808 9.76083 23.5696C6.14467 24.3733 5.382 21.7812 5.382 21.7812C4.7905 20.2427 3.93575 19.8313 3.93575 19.8313C2.75817 19.0049 4.02675 19.0219 4.02675 19.0219C5.33217 19.1146 6.01792 20.3954 6.01792 20.3954C7.17708 22.4335 9.061 21.8445 9.80417 21.5043C9.92117 20.6418 10.2559 20.0551 10.6275 19.7216C7.74042 19.3882 4.706 18.2419 4.706 13.1347C4.706 11.6798 5.20975 10.4906 6.04392 9.55804C5.89767 9.22117 5.45892 7.8658 6.15767 6.03C6.15767 6.03 7.24642 5.67279 9.73267 7.39668C10.7727 7.10051 11.8777 6.95356 12.9827 6.94677C14.0877 6.95356 15.1927 7.10051 16.2327 7.39668C18.7027 5.67279 19.7914 6.03 19.7914 6.03C20.4902 7.8658 20.0514 9.22117 19.9214 9.55804C20.7502 10.4906 21.2539 11.6798 21.2539 13.1347C21.2539 18.2555 18.2152 19.3825 15.3227 19.7103C15.7777 20.1105 16.2002 20.9278 16.2002 22.1769C16.2002 23.9607 16.1839 25.3941 16.1839 25.827C16.1839 26.1763 16.4114 26.5934 17.0777 26.46C22.2788 24.7102 26 19.7115 26 13.8299C26 6.46861 20.1793 0.5 13 0.5Z"
						fill="black"
					/>
				</svg>
				<span>{footerItem.buttonText}</span>
			</a>
		</div>
	);
};
