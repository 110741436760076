// data
import realTimeData from '../../data/realTimeData.json';
import { AnimationWrap } from '../../helper/animationWrap';
import TitleHelper from '../../helper/TitleHelper';

export const Title = () => {
	return (
		<AnimationWrap>
			<TitleHelper
				heading={realTimeData.title}
				special={realTimeData.special}
				titleType="h2"
			/>
		</AnimationWrap>
	);
};
