import React from 'react';

interface TitleHelperProps {
	heading: string;
	special?: string;
	titleType: 'h1' | 'h2' | 'h3';
	extraClass?: string;
	includeBreak?: boolean;
}

const TitleHelper: React.FC<TitleHelperProps> = ({
	heading,
	special,
	titleType,
	extraClass,
	includeBreak,
}) => {
	const prepareTitle = (): {
		before: string;
		special: string;
		after: string;
	} => {
		const titleArr = heading.split('[special]');

		const beforeSpecialString = titleArr[0] ?? '';
		const specialString = special ?? '';
		const afterSpecialString = titleArr[1] ?? '';

		return {
			before: beforeSpecialString,
			special: specialString,
			after: afterSpecialString,
		};
	};

	const { before, special: specialText, after } = prepareTitle();

	let TitleElement: keyof JSX.IntrinsicElements;
	if (titleType === 'h1') {
		TitleElement = 'h1';
	} else if (titleType === 'h2') {
		TitleElement = 'h2';
	} else {
		TitleElement = 'h3';
	}

	const titleClass = extraClass ? `${extraClass}` : '';

	return (
		<TitleElement className={titleClass}>
			{before}
			{specialText && <span>{specialText}</span>}
			{includeBreak && <br />}
			{after}
		</TitleElement>
	);
};

export default TitleHelper;
