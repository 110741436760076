// types
import { BulletsItem } from './types';

// images
import icon_1 from '../../assets/img/bullet-icons/icon-1.svg';
import icon_2 from '../../assets/img/bullet-icons/icon-2.svg';
import icon_3 from '../../assets/img/bullet-icons/icon-3.svg';
import icon_4 from '../../assets/img/bullet-icons/icon-4.svg';
import icon_5 from '../../assets/img/bullet-icons/icon-5.svg';
import icon_6 from '../../assets/img/bullet-icons/icon-6.svg';
import { AnimationWrap } from '../../helper/animationWrap';

const images = [icon_1, icon_2, icon_3, icon_4, icon_5, icon_6];

type BulletsProps = {
	bulletsItem: BulletsItem[];
};

export const Bullets = ({ bulletsItem }: BulletsProps) => {
	return (
		<AnimationWrap
			extraClass="reat-time__bullets"
			tag="div"
			animationClass="show-animation-3"
		>
			{bulletsItem.map((bullet, index) => (
				<div className="item" key={index}>
					<div className="item__title_wrap">
						<img
							width="32"
							height="32"
							src={images[index]}
							alt=""
						/>
						<h3>{bullet.title}</h3>
					</div>

					<p>{bullet.description}</p>
				</div>
			))}
		</AnimationWrap>
	);
};
