// components
import { Bullets, Image, Paragraph, Title } from './';

// data
import bulletsData from '../../data/bulletsData.json';

export const RealTime = () => {
	return (
		<div className="reat-time__wrap">
			<div className="reat-time__title">
				<Image />
				<Title />
				<Paragraph />
			</div>
			<Bullets bulletsItem={bulletsData} />
		</div>
	);
};
