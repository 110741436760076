import { useInView } from 'react-intersection-observer';

interface PluginItemProps {
	item: {
		icon: string;
		number: string;
		title: string;
		description: string;
	};
	index: number;
	images: string[];
}

export const PluginItem: React.FC<PluginItemProps> = ({
	item,
	index,
	images,
}) => {
	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	return (
		<div
			ref={ref}
			className={`item hide-animation ${
				inView ? `show-animation-${index + 2}` : ''
			}`}
			key={index}
		>
			<div className="img-wrap">
				<img
					width={407}
					height={160}
					src={images[2]}
					alt=""
				/>
			</div>
			<div className="title">
				<span className="number">{item.number}</span>
				<span className="text">{item.title}</span>
			</div>
			<p>{item.description}</p>
		</div>
	);
};
