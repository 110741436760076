import { useState, useEffect } from 'react';

interface ScrollPosition {
	desktopScroll: boolean;
	mobileScroll: boolean;
	isStiky: boolean;
}

const useScrollPosition = (): ScrollPosition => {
	const [desktopScroll, setDesktopScroll] = useState(false);
	const [mobileScroll, setMobileScroll] = useState(false);
	// setstiky state
	const [isStiky, setIsStiky] = useState(false);

	useEffect(() => {
		const checkScrollYPosition = () => {
			setDesktopScroll(window.scrollY > 530);

			setIsStiky(window.scrollY >= 60);

			if (window.innerWidth > 700) {
				setMobileScroll(window.scrollY > 1000);
			} else if (window.innerWidth > 550) {
				setMobileScroll(window.scrollY > 800);
			} else {
				setMobileScroll(window.scrollY > 410);
			}
		};

		window.addEventListener('scroll', checkScrollYPosition);

		return () => {
			window.removeEventListener('scroll', checkScrollYPosition);
		};
	}, []);

	return { desktopScroll, mobileScroll, isStiky };
};

export default useScrollPosition;
