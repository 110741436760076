import { AnimationWrap } from '../../helper/animationWrap';

import { FooterItem } from './types';

type FooterProps = {
	footerItem: FooterItem;
};

export const Rights = ({ footerItem }: FooterProps) => {
	return (
		<AnimationWrap
			extraClass="rights"
			tag="div"
			animationClass="show-animation-2"
		>
			<p>{footerItem.copyright}</p>
			<p>{footerItem.madeWithLove}</p>
		</AnimationWrap>
	);
};
