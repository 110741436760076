import { useState } from 'react';
import { AnimationWrap } from '../../helper/animationWrap';
import { FAQ } from './';
import faqData from '../../data/faqData.json';

interface FAQItem {
	question: string;
	answer: string[];
	open: boolean;
}

export const FAQS = () => {
	const [faqs, setFaqs] = useState<FAQItem[]>(faqData);

	const toggleFAQ = (index: number) => {
		setFaqs((prevFaqs) =>
			prevFaqs.map((faq, i) => {
				if (i === index) {
					return { ...faq, open: !faq.open };
				} else {
					return { ...faq, open: faq.open };
				}
			}),
		);
	};

	return (
		<div className="faqs">
			<div className="faqs__wrap">
				<div className="faqs__title">
					<AnimationWrap
						extraClass="faq__items"
						tag="h2"
						animationClass="show-animation-1"
					>
						FAQ's
					</AnimationWrap>
				</div>

				<AnimationWrap
					extraClass="faq__items"
					tag="div"
					animationClass="show-animation-3"
				>
					{faqs.map((faq, index) => (
						<FAQ
							faq={faq}
							index={index}
							key={index}
							toggleFAQ={toggleFAQ}
						/>
					))}
				</AnimationWrap>
			</div>
		</div>
	);
};
