import { useState, useEffect } from 'react';

const useMobileCheck = (): boolean => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkWindowSize = () => {
			setIsMobile(window.innerWidth <= 992);
		};

		checkWindowSize();

		const handleResize = () => {
			checkWindowSize();
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobile;
};

export default useMobileCheck;
