// image
import icon from '../../assets/img/plugin.svg';
import { AnimationWrap } from '../../helper/animationWrap';

export const Image = () => {
	return (
		<AnimationWrap tag="span" animationClass="show-animation-1">
			<img width="120" height="120" src={icon} alt="plugin icon" />
		</AnimationWrap>
	);
};
