// components
import { Image, Title, PluginItem } from './';

// types
import { PluginItemTypes } from './types';

// images
import plugin_1 from '../../assets/img/plugin/plugin-1.svg';
import plugin_2 from '../../assets/img/plugin/plugin-2.svg';
import plugin_3 from '../../assets/img/plugin/plugin-3.svg';

const images = [plugin_1, plugin_2, plugin_3];

// plugin Item type
type PluginProps = {
	pluginItem: PluginItemTypes[];
};

export const PlugIn = ({ pluginItem }: PluginProps) => {
	return (
		<div className="plugin__wrap">
			<div className="plugin__title">
				<Image />
				<Title />
			</div>
			<div className="plugin-items">
				{pluginItem.map((item, index) => (
					<PluginItem
						item={item}
						index={index}
						images={images}
						key={index}
					/>
				))}
			</div>
		</div>
	);
};
