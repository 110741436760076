// data
import realTimeData from '../../data/realTimeData.json';
import { AnimationWrap } from '../../helper/animationWrap';

export const Paragraph = () => {
	return (
		<AnimationWrap tag="p" animationClass="show-animation-3">
			{realTimeData.content}
		</AnimationWrap>
	);
};
