import React, { useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface AnimationWrapProps {
	children: React.ReactNode;
	extraClass?: string;
	tag?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
	animationClass?: string;
}

export const AnimationWrap: React.FC<AnimationWrapProps> = ({
	children,
	extraClass,
	tag: Tag = 'span' || 'div',
	animationClass = 'show-animation-2',
}) => {
	const ref = useRef<Element>(null);

	const { inView, ref: inViewRef } = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	useEffect(() => {
		if (ref.current) {
			inViewRef(ref.current);
		}
	}, [inViewRef]);

	return (
		<Tag
			className={`${extraClass ? `${extraClass} ` : ''} hide-animation ${
				inView ? animationClass : ''
			}`}
			ref={ref}
		>
			{children}
		</Tag>
	);
};
