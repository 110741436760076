// SEO Support
import { Helmet } from 'react-helmet-async';

// componnets
import { Hero } from './components/hero/index';
import { Nav } from './components/nav/index';
import { PlugIn } from './components/plugInSec/index';
import { RealTime } from './components/realTime/index';
import { FAQS } from './components/faqs';
import { Footer } from './components/footer/index';

// data
import links from './data/navData.json';
import pluginItemData from './data/pluginItems.json';
import seoData from './data/seo.json';

const App = () => {
	return (
		<>
			<Helmet>
				<title>{seoData.title}</title>
				<meta name="description" content={seoData.description} />
			</Helmet>

			<Nav navItems={links} />
			<Hero />
			<RealTime />
			<PlugIn pluginItem={pluginItemData} />
			<FAQS />
			<Footer />
		</>
	);
};

export default App;

// push test
